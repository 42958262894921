<template>
	<div class="main">
		<template v-if="!showEdit">
			<span class="title">县域概况</span>
			<div class="table" :border="true">
				<el-table :data="showAreaList" height="100%" key="table">
					<el-table-column type="index" label="序号" width="300" />
					<el-table-column prop="label" label="地区" />
					<el-table-column label="操作">
						<template #default="{row}">
							<el-button size="small" type="primary" @click="toEdit(row)">
								去填写
							</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</template>

		<template v-else>
			<header class="edit_header">
				<el-button type="text" @click="showEdit = false">
					<i class="el-icon-arrow-left"></i>
					返回
				</el-button>
				<span class="title">县域概况({{ editArea.label }})</span>
				<el-button size="small" type="primary" @click="saveData">保存</el-button>
			</header>

			<div class="table edit_table" :border="true">
				<el-table
					:data="getTableLine"
					height="100%"
					:border="true"
					key="edit_table"
					:cell-class-name="cellClassName"
				>
					<el-table-column type="index" label="序号" width="80" />
					<el-table-column label="名称">
						<template #default="{row}">
							<span>{{ row }} </span>
						</template>
					</el-table-column>
					<el-table-column
						v-for="it in getTableField"
						:key="it.id"
						:label="it.title"
						min-width="100"
					>
						<template #default="{row}">
							<div class="edit_box" v-if="!isDisabled(row, it.id)">
								<input
									v-if="['text', 'number'].includes(it.type)"
									:type="it.type"
									:value="data?.[row]?.[it.id]"
									@input="change(row, it.id, $event.target.value)"
								/>
							</div>
							<el-tooltip
								v-else
								effect="dark"
								:content="data?.[row]?.[it.id]"
								:disabled="!data?.[row]?.[it.id]"
								placement="top"
							>
								<span class="edit_box show_value">
									{{ data?.[row]?.[it.id] }}
								</span>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</template>
	</div>
</template>

<script setup>
import { computed, ref } from "vue";
import store from "@/vuex/index.js";
import { areaList } from "@/variable/area.js";
import api from "@/api/methods/index.js";
import { Message } from "element-ui";

const showEdit = ref(false);
const toEdit = async (row) => {
	editArea.value = row;
	await getTableConfig();
	await getData();
	showEdit.value = true;
};

// 展示区县列表
const showAreaList = computed(() => {
	const authority = store.state?.user?.current_user?.xygk_power?.area;
	if (authority == -1) return areaList;
	return areaList.filter((it) => it.value == authority);
});

// 表格配置
const tableConfig = ref({});
const getTableConfig = async () => {
	const msg = await api.quxian.xygkConfig({
		arg: {
			appid: "yuanqing",
			group_id: "yuanqing",
			data: ["data.conf"],
		},
	});
	tableConfig.value = msg.data.rows["data.conf"];
	console.log("tableConfig", JSON.parse(JSON.stringify(tableConfig.value)));
};

// 修改
const editArea = ref({});
const data = ref({});
const getTableLine = computed(() => {
	const all_list =
		tableConfig.value.list?.find?.((it) => it.area == editArea.value.label)
			?.xiang || [];
	return all_list;
});
const getTableField = computed(() => tableConfig.value.fields);
const isDisabled = (group, field) => {
	const collect_key = tableConfig.value.calc?.sumFields || [];
	if (group == editArea.value.label && collect_key.includes(field)) return true;
};
const change = (group, key, value) => {
	data.value = {
		...data.value,
		[group]: {
			...(data.value[group] || {}),
			[key]: value,
		},
	};
	setCollect();
	console.log("data", data.value);
};
const setCollect = () => {
	const collect_key = tableConfig.value.calc?.sumFields || [];
	const result = getTableLine.value
		.filter((it) => it != editArea.value.label)
		.reduce((pre, now) => {
			const item = data.value[now] || {};
			collect_key.forEach((key) => {
				pre[key] = +(pre[key] || 0) + +(item[key] || 0);
			});

			return pre;
		}, {});
	data.value = {
		...data.value,
		[editArea.value.label]: {
			...(data.value[editArea.value.label] || {}),
			...result,
		},
	};
};
const cellClassName = ({ row, columnIndex }) => {
	const field = getTableField.value[columnIndex - 2];
	if (isDisabled(row, field?.id)) return "disabled";
	if ([0, 1].includes(columnIndex)) return "system_column";
};

const getData = async () => {
	const msg = await api.quxian.xygkGet({
		arg: {
			appid: "yuanqing",
			group_id: "yuanqing",
			area: editArea.value.label,
			data: ["data.val"],
		},
	});
	data.value = msg.data.rows["data.val"]?.[editArea.value.label] || {};
};
const saveData = async () => {
	const msg = await api.quxian.xygkSave({
		arg: {
			appid: "yuanqing",
			group_id: "yuanqing",
			data: {
				"data.val": {
					[editArea.value.label]: data.value,
				},
			},
		},
	});
	if (msg.data.rows.code == 1) {
		Message({
			type: "success",
			message: "保存成功",
		});
		showEdit.value = false;
	}
};
</script>

<style lang="less" scoped>
.main {
	display: flex;
	flex-direction: column;
	align-items: center;

	.table {
		width: 100%;
		flex: 1;
		padding: 0 32px;
		box-sizing: border-box;
		overflow: hidden;

		::v-deep(.disabled) {
			background-color: rgb(241, 241, 241) !important;
			cursor: not-allowed;
		}
		::v-deep(.system_column) {
			background-color: #f8f8f8 !important;
		}
	}

	.title {
		font-size: 20px;
		padding: 10px;
	}
}

.edit_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 20px;
	box-sizing: border-box;
	border-bottom: 1px solid #f2f2f2;
}
.edit_table {
	padding: 10px 20px !important;

	.edit_box {
		width: 100%;
		height: 40px;
		box-sizing: border-box;
		overflow: hidden;
		position: relative;

		input {
			width: 100%;
			height: 100%;
			background-color: transparent;
			padding: 0 4px;
		}

		&.show_value {
			width: 100%;
			height: 40px;
			line-height: 40px;
			padding: 0 4px;
			box-sizing: border-box;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

/* 隐藏 Chrome、Safari 等 WebKit 浏览器上的上下箭头 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* 隐藏 Firefox 上的上下箭头 */
input[type="number"] {
	-moz-appearance: textfield;
}

/* 移除默认样式 */
input[type="number"] {
	appearance: none;
}
</style>
